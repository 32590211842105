import React from 'react';
import PropTypes from 'prop-types';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import moment from 'moment';

import { Authenticated, DateHelpers } from 'common';

import CaseUtils from '../../utils/CaseUtils';
import CaseStore from '../stores/CaseStore';
import CaseLogStore from '../stores/CaseLogStore';
import CaseViewStore from '../stores/CaseViewStore';
import { LogValue, MultilineInput, TextFormatter } from '../../ui';

const log = PropTypes.shape({
  created: PropTypes.string,
  createdBy: PropTypes.shape({}),
  description: PropTypes.string,
});

const logProps = PropTypes.oneOfType([PropTypes.arrayOf(log), log]);

const Created = ({ date, isAMultiple, timezone }) => {
  // Is multiple is for the condensed view logs
  const m = moment(date).tz(timezone);
  const midnight = moment()
    .tz(timezone)
    .millisecond(0)
    .second(0)
    .minute(0)
    .hour(0);
  const yesterday = moment(midnight).subtract(1, 'days');

  let txt = '';
  if (m.diff(midnight) >= 0) txt = isAMultiple ? 'Today' : m.fromNow();
  else if (m.diff(yesterday) >= 0)
    txt = `Yesterday ${isAMultiple ? '' : m.format('h:mm A z')}`;
  else txt = m.format(isAMultiple ? 'MMM Do, YYYY' : 'lll z');

  return <div className="timestamp">{txt}</div>;
};

Created.propTypes = {
  date: PropTypes.string,
  isAMultiple: PropTypes.bool,
};

const systemName = createdBy => {
  if (createdBy) {
    return (
      <span>
        {createdBy.firstName} {createdBy.lastName}
      </span>
    );
  }
  return <span>Protenus System</span>;
};

const Log = ({ log, timezone }) => {
  let { created, createdBy, description } = log;
  // if the log is an array that means it's a collection of views from the same user
  if (Array.isArray(log)) {
    // we dont want to display the view if it was viewed by a protenus employee
    if (
      log[0].createdBy.roles.slice().includes('ADMIN') &&
      log[0].createdBy.roles.slice().includes('VIEW ONLY')
    ) {
      return null;
    }

    created = log[log.length - 1].created;
    description = 'viewed this case';
    createdBy = log[0].createdBy;
  }

  return (
    <li className="log__item">
      <ul>
        <li className="log__item-header">
          <div>
            {systemName(createdBy)} {description || 'viewed this case'}{' '}
            <LogValue strong log={log} />
          </div>
          <Created
            date={created}
            timezone={timezone}
            isAMultiple={Array.isArray(log)}
          />
        </li>
      </ul>
    </li>
  );
};

Log.propTypes = {
  log: logProps,
};

const CommentLog = ({ log }) => {
  const { created, createdBy, description, timezone } = log;
  const onDelete = () => CaseLogStore.delete(log);

  let deleteBtn = (
    <span className="prot-a" onClick={onDelete}>
      Delete
    </span>
  );

  if (!CaseLogStore.canDelete(log)) {
    deleteBtn = '';
  }

  return (
    <li className="log__item">
      <ul>
        <li className="log__item-header">
          <div>
            {createdBy.firstName} {createdBy.lastName} commented on the case
          </div>
          <Created date={created} timezone={timezone} />
          {deleteBtn}
        </li>
        <li className="log__item-content">
          <p>
            <TextFormatter text={description} />
          </p>
        </li>
      </ul>
    </li>
  );
};

CommentLog.propTypes = {
  log: logProps,
};

function sortFunc(a, b) {
  return (Array.isArray(a) ? a[a.length - 1].created : a.created) <
    (Array.isArray(b) ? b[b.length - 1].created : b.created)
    ? 1
    : -1;
}

function combineAndSortCaseLogs() {
  const combinedViews = {};
  CaseViewStore.views.forEach(view => {
    const viewKey = `${view.created.slice(0, 10)}-${view.createdBy?.firstName +
      view.createdBy?.lastName}`;
    if (combinedViews[viewKey]) {
      combinedViews[viewKey].push(view);
    } else {
      combinedViews[viewKey] = [view];
    }
  });

  const sortedViews = Object.keys(combinedViews).map(
    viewID => combinedViews[viewID]
  );
  return CaseLogStore.logs.concat(sortedViews).sort(sortFunc);
}

const CaseHistory = observer(
  class extends React.Component {
    // Observable
    comment = '';

    onChange = val => {
      this.comment = val;
    };

    onFocus = () => {
      CaseStore.commentFormActive = true;
    };

    onCancel = () => {
      this.comment = '';
      CaseStore.commentFormActive = false;
    };

    onSave = () => {
      if (this.comment.length > 0) {
        CaseLogStore.comment(this.comment).then(() => {
          this.comment = '';
          CaseStore.commentFormActive = false;
          CaseUtils.synchronizeActivity();
        });
      }
    };

    render() {
      const timezone = DateHelpers.getCurrentAppUserTimezone();
      const history = combineAndSortCaseLogs().map((h, idx) => {
        if (h.action === 'COMMENT') {
          return <CommentLog key={idx} log={{ ...h, timezone }} />;
        }
        return <Log key={idx} log={h} timezone={timezone} />;
      });

      const cn = classnames({
        inactive: !CaseStore.commentFormActive,
      });

      return (
        <div className="padd-all">
          <ul>
            <Authenticated permission="CASE_COMMENT_CREATE">
              <li className="log__add">
                <form>
                  <ul className={cn}>
                    <MultilineInput
                      value={this.comment}
                      label="Add a Comment"
                      onChange={this.onChange}
                      onFocus={this.onFocus}
                    />
                    <li className="add-comment comment-footer">
                      <div>
                        <span className="prot-a" onClick={this.onCancel}>
                          Cancel
                        </span>
                      </div>
                      <div>
                        <span
                          className={classnames('prot-a', {
                            disabled: this.comment.length === 0,
                          })}
                          onClick={this.onSave}
                        >
                          Save
                        </span>
                      </div>
                    </li>
                  </ul>
                </form>
              </li>
            </Authenticated>
            {history}
          </ul>
        </div>
      );
    }
  }
);

decorate(CaseHistory, {
  comment: observable,
});

CaseHistory.displayName = 'CaseHistory';

export default CaseHistory;
