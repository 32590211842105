import React from 'react';
import PropTypes from 'prop-types';
import PatientBox from '../PatientBox';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import CaseStore from '../../case/stores/CaseStore';
import { PRIVACY_PRODUCT, PermissionStore, DateHelpers } from 'common';
import PrintSettingsStore from '../../stores/PrintSettingsStore';
import Store from '../../activity/stores/Store';
import RelationshipStore from '../../stores/RelationshipStore';
import CaseCountByDBRefStore from '../../stores/CaseCountByDBRefStore';
import PatientAuthorizationsStore from '../../stores/PatientAuthorizationsStore';
import PatientCasesStore from '../../stores/PatientCasesStore';
import PatientEncountersStore from '../stores/PatientEncountersStore';
import PatientVipStore from '../../stores/PatientVipStore';
import { showTwoColumns } from '../../people/ContainerHelpers';

export const mapProps = ({
  CaseStore,
  PatientAuthorizationsStore,
  PatientCasesStore,
  PatientEncountersStore,
  CaseCountByDBRefStore,
  PermissionStore,
  RelationshipStore,
  PatientVipStore,
  PrintSettingsStore,
  Store,
  history,
  forPage,
}) => {
  const employeeId =
    forPage === 'activity' ? Store.userIdParam : CaseStore.userId;
  const patient = forPage === 'activity' ? Store.patient : CaseStore.patient;
  const patientId =
    forPage === 'activity' ? Store.patientIdParam : CaseStore.patientId;

  const timezone = DateHelpers.getCurrentAppUserTimezone();

  const otherAliasID = RelationshipStore.getOtherAliasId(
    'patients',
    patientId,
    patient && patient.source
  );

  const subPatientBoxProps = {
    authorizations: PatientAuthorizationsStore.infoById.get(patientId),
    canManageAuths: PermissionStore.has('ACCESS_AUTH_CREATE'),
    canManageVIPs: PermissionStore.has('VIP_REGISTRATION_CREATE'),
    canViewAuths: PermissionStore.has('ACCESS_AUTH_VIEW'),
    canViewVIPs: PermissionStore.has('VIP_REGISTRATION_VIEW'),
    employeeId,
    patientId,
    userLinkForOppositeAlias:
      otherAliasID && PermissionStore.getProducts().includes(PRIVACY_PRODUCT)
        ? Store.getUserLink({ userId: otherAliasID })
        : null,
    vipRegistrations: PatientVipStore.infoById.get(patientId),
  };

  const patientRelationships =
    RelationshipStore.patientRelationships.get(patientId) || [];

  return {
    aliases: RelationshipStore.getAliases('patients', patientId),
    currentView: forPage,
    fixedWidth: forPage === 'activity' && Store.isUserPatientActivityView,
    maxWidthHalf: forPage === 'activity',
    futureEncounters: PatientEncountersStore.futureEncounters,
    isMultiPatient: forPage === 'case' && CaseStore.multiPatient,
    isSelfAccess: forPage === 'case' && CaseStore.isSelfAccess,
    printSettings: PrintSettingsStore.printSettings,
    linkAlias: alias => history.push(Store.findLink(alias)),
    otherAliases: RelationshipStore.getOtherAliases(
      'patients',
      patientId,
      patient && patient.source
    ),
    orderLast: forPage === 'activity' && !Store.isPatientFocus,
    pastEncounters: PatientEncountersStore.pastEncounters,
    patientLink:
      forPage === 'activity'
        ? Store.getPatientLink({
            patientId,
          })
        : CaseStore.personLink('patient'),
    patient,
    patientCases: PatientCasesStore.infoById.get(patientId) || [],
    patientCaseCount: CaseCountByDBRefStore.infoById.get(patientId) || 0,
    patientId,
    patientRelationships,
    mostRecentEncounter: PatientEncountersStore.mostRecent,
    subPatientBoxProps,
    showTwoColumns: showTwoColumns({
      forPage,
      caseStore: CaseStore,
      activityStore: Store,
    }),
    timezone,
    loading: Store.loading,
  };
};

const PatientBoxContainer = observer(({ history, forPage }) => (
  <PatientBox
    {...mapProps({
      CaseStore,
      CaseCountByDBRefStore,
      PatientAuthorizationsStore,
      PatientCasesStore,
      PatientEncountersStore,
      PatientVipStore,
      PermissionStore,
      PrintSettingsStore,
      RelationshipStore,
      Store,
      history,
      forPage,
    })}
  />
));

PatientBoxContainer.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  forPage: PropTypes.oneOf(['activity', 'case']).isRequired,
};

PatientBoxContainer.displayName = 'PatientBoxContainer';

export default withRouter(PatientBoxContainer);
