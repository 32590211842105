import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import PersonAside from '../../people/PersonBox/PersonAside';
import PersonHeader from '../../people/PersonBox/PersonHeader';
import GenericPatientInfoHeader from '../../people/PersonBox/GenericPatientInfoHeader';
import PatientDetails from '../PatientDetails';
import Encounters from '../Encounters';
import Contacts from '../../people/Contacts';
import SubPatientBox from '../SubPatientBox';
import MaybeTwoColumns from '../../people/PersonBox/MaybeTwoColumns';
import { useFetchCaseCount } from '../../stores/CaseCountByDBRefStore/useFetchCaseCount';

const PatientBox = ({
  aliases,
  orderLast,
  fixedWidth,
  futureEncounters,
  isSelfAccess,
  linkAlias,
  currentView,
  printSettings,
  isMultiPatient,
  otherAliases,
  maxWidthHalf,
  pastEncounters,
  patient,
  patientCases,
  patientCaseCount,
  patientId,
  patientLink,
  patientRelationships,
  mostRecentEncounter,
  subPatientBoxProps,
  showTwoColumns,
  timezone,
  loading,
}) => {
  useFetchCaseCount(patientId, 'patient');
  // In some situations we are fed a patient snapshot which is immediately
  // available. In other situations we have to wait for an async request to come
  // back with the patient.
  if (!patient) patient = {};

  const caseIdIfOnlyOneCaseForPerson =
    patientCases.length === 1 ? patientCases[0].id : null;

  const firstColumnParts = () => (
    <>
      {patientId && <SubPatientBox {...subPatientBoxProps} />}
      <PatientDetails
        isSelfAccess={isSelfAccess}
        printSettings={printSettings}
        patient={patient}
        mostRecentEncounter={mostRecentEncounter}
        patientRelationships={patientRelationships}
        timezone={timezone}
      />
    </>
  );

  const secondColumnParts = () => (
    <>
      <Contacts contacts={patient.contacts || []} />
      <hr />
      <Encounters
        futureEncounters={futureEncounters}
        pastEncounters={pastEncounters}
      />
    </>
  );

  let component;
  if (!loading)
    component = patientId ? (
      <>
        <PersonHeader
          aliases={aliases}
          caseIdIfOnlyOneCaseForPerson={caseIdIfOnlyOneCaseForPerson}
          currentView={currentView}
          otherAliases={otherAliases}
          findLink={alias => linkAlias(alias)}
          numCases={patientCaseCount}
          linkToOnlyCase={currentView !== 'case'}
          person={patient}
          personId={patientId}
          personLink={patientLink}
          personType="patient"
        />
        <MaybeTwoColumns
          key={patientId}
          showTwoColumns={showTwoColumns}
          moreLabel="Contacts & Encounters"
          backLabel="Details"
          renderFirstColumn={firstColumnParts}
          renderSecondColumn={secondColumnParts}
        />
      </>
    ) : (
      <GenericPatientInfoHeader type={isMultiPatient ? 'multi' : 'none'} />
    );

  return (
    <PersonAside
      fixedWidth={fixedWidth}
      maxWidthHalf={maxWidthHalf}
      orderLast={orderLast}
    >
      {component}
    </PersonAside>
  );
};

PatientBox.propTypes = {
  aliases: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  currentView: PropTypes.string,
  fixedWidth: PropTypes.bool,
  futureEncounters: PropTypes.array,
  printSettings: PropTypes.shape({}),
  isMultiPatient: PropTypes.bool,
  linkAlias: PropTypes.func,
  mostRecentEncounter: PropTypes.shape({}),
  orderLast: PropTypes.bool,
  otherAliases: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  patient: PropTypes.shape({
    contacts: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  }),
  pastEncounters: PropTypes.array,
  patientCases: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  patientCaseCount: PropTypes.number,
  patientId: PropTypes.string,
  patientLink: PropTypes.string,
  subPatientBoxProps: PropTypes.shape({}),
  showTwoColumns: PropTypes.bool.isRequired,
  patientRelationships: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({})
  ),
  loading: PropTypes.bool,
};

PatientBox.defaultProps = {
  aliases: [],
  fixedWidth: false,
  futureEncounters: [],
  isMultiPatient: false,
  linkAlias: () => {},
  orderLast: false,
  otherAliases: [],
  pastEncounters: [],
  patient: {},
  patientCases: [],
  patientId: '',
  patientLink: '',
  printSettings: {},
  mostRecentEncounter: {},
};

PatientBox.displayName = 'PatientBox';

export default PatientBox;
